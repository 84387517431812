import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { collection, Timestamp } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { useMemo } from 'react'
import { ContactSubmission, RFISubmission, SellSubmission } from 'core/types/form_submission'

export type Submission = (SellSubmission | RFISubmission | ContactSubmission) & {
  id: string
  type: 'sell' | 'rfi' | 'contact'
  createdAt: {
    seconds: number
    nanoseconds: number
  }
  status: 'New' | 'Read' | 'Completed'
}

const COLLECTIONS = {
  SELL: 'form_sell',
  RFI: 'form_rfi',
  CONTACT: 'form_contact'
} as const

export const useSubmissions = () => {
  // Fetch sell submissions
  const { data: sellSubmissions, isLoading: isLoadingSell } = useFirestoreQuery(
    ['submissions', COLLECTIONS.SELL],
    collection(db, COLLECTIONS.SELL),
    { subscribe: true },
    {
      select: snapshot => {
        if (!snapshot?.docs) return []
        return snapshot.docs.map(doc => ({
          id: doc.id,
          type: 'sell' as const,
          ...doc.data()
        })) as Submission[]
      }
    }
  )

  // Fetch RFI submissions
  const { data: rfiSubmissions, isLoading: isLoadingRfi } = useFirestoreQuery(
    ['submissions', COLLECTIONS.RFI],
    collection(db, COLLECTIONS.RFI),
    { subscribe: true },
    {
      select: snapshot => {
        if (!snapshot?.docs) return []
        return snapshot.docs.map(doc => ({
          id: doc.id,
          type: 'rfi' as const,
          ...doc.data()
        })) as Submission[]
      }
    }
  )

  // Fetch contact submissions
  const { data: contactSubmissions, isLoading: isLoadingContact } = useFirestoreQuery(
    ['submissions', COLLECTIONS.CONTACT],
    collection(db, COLLECTIONS.CONTACT),
    { subscribe: true },
    {
      select: snapshot => {
        if (!snapshot?.docs) return []
        return snapshot.docs.map(doc => ({
          id: doc.id,
          type: 'contact' as const,
          ...doc.data()
        })) as Submission[]
      }
    }
  )

  // Combine all submissions
  const submissions = useMemo(() => {
    return [
      ...(sellSubmissions || []),
      ...(rfiSubmissions || []),
      ...(contactSubmissions || [])
    ].sort((a, b) => 
      // Sort by seconds, then nanoseconds if seconds are equal
      b.createdAt.seconds - a.createdAt.seconds || 
      b.createdAt.nanoseconds - a.createdAt.nanoseconds
    )
  }, [sellSubmissions, rfiSubmissions, contactSubmissions])

  console.log('Combined submissions:', submissions)

  return {
    submissions,
    isLoading: isLoadingSell || isLoadingRfi || isLoadingContact
  }
}
