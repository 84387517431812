import { useSnackbar } from 'contexts/snackBarContext'
import { db, storage } from 'core/config/firebase'
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import { deleteObject, listAll, ref } from 'firebase/storage'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

interface ImportedExcel {
  id: string
  fileName: string
  importedBy: string
  importedAt: string
  vehicles?: Vehicle[]
}

interface useImportedVehiclesExcelsOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
  dontShowUpdates?: boolean
}

export const useImportedVehiclesExcels = (options?: useImportedVehiclesExcelsOptions) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const queryKey = 'imported_vehicles'

  const fetchImportedExcels = useCallback(async () => {
    const snapshot = await getDocs(collection(db, queryKey))
    return snapshot.docs.map(
      doc =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as ImportedExcel,
    )
  }, [])

  const { data: importedExcels, isLoading, isError, error, refetch } = useQuery(queryKey, fetchImportedExcels)

  const addImportedExcel = useMutation(
    async (newExcel: Omit<ImportedExcel, 'id'>) => {
      const docRef = await addDoc(collection(db, 'imported_vehicles'), newExcel)
      return { id: docRef.id, ...newExcel }
    },
    {
      onSuccess: () => {
        if (!options?.dontShowUpdates) {
          showSnackbar('Excel file imported successfully', 'success')
        }
        queryClient.invalidateQueries(queryKey)
        options?.onSuccess?.()
      },
      onError: (error: Error) => {
        if (!options?.dontShowUpdates) {
          showSnackbar('Error importing Excel file', 'error')
        }
        console.error('Error importing Excel file:', error)
        options?.onError?.(error)
      },
    },
  )

  const deleteImages = async (excelId: string) => {
    const storageRef = ref(storage, `imported_vehicles/${excelId}`)
    try {
      const fileList = await listAll(storageRef);
  
      const deletePromises = fileList.items.map((fileRef) => deleteObject(fileRef));
      await Promise.all(deletePromises);
  
      await deleteObject(storageRef)
      console.log('Image deleted from Firebase Storage')
    } catch (error) {
      console.log('Image does not exist in Firebase Storage')
    }
  }

  const deleteImportedExcel = useMutation(
    async (id: string) => {
      await deleteDoc(doc(db, 'imported_vehicles', id))
     
      return id
    },
    {
      onSuccess: (id) => {
        if (!options?.dontShowUpdates) {
          showSnackbar('Excel file removed successfully', 'success')
        }
        deleteImages(id)
        queryClient.invalidateQueries(queryKey)
        options?.onSuccess?.()
      },
      onError: (error: Error) => {
        if (!options?.dontShowUpdates) {
          showSnackbar('Error removing Excel file', 'error')
        }
        console.error('Error removing Excel file:', error)
        options?.onError?.(error)
      },
    },
  )

  return {
    importedExcels,
    isLoading,
    isError,
    error,
    refetch,
    addImportedExcel: addImportedExcel.mutateAsync,
    deleteImportedExcel: deleteImportedExcel.mutateAsync,
  }
}
