import { Box, Grid, Paper, Typography, Stack, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material'
import { LineChart, PieChart } from '@mui/x-charts'
import { useAdmin } from 'contexts/AdminContext'
import { formatPrice } from 'core/utils/inventoryUtils'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import InventoryIcon from '@mui/icons-material/Inventory'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TimerIcon from '@mui/icons-material/Timer'
import { useInventory } from 'hooks/useInventory'
import { DatePicker } from '@mui/x-date-pickers'
import { TopModelsModal } from './components/TopPerformance'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PendingSalesOverview } from './components/PendingSale'
import { PendingSalesModal } from './components/PendingSaleDetails'
import { TopPerformancePreview } from './components/TopPerformanceCard'
import { SalesTrendChart } from './components/SalesTrendChart'
import { ProfitDistributionChart } from './components/ProfitDistributionChart'
import { VehicleTypeDistribution } from './components/VehicleTypeDistribution'
import { useAuth } from 'contexts/AuthContext'

const MetricCard = ({ title, value, icon, trend }: { title: string; value: string; icon: React.ReactNode; trend?: string }) => (
  <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, height: '100%' }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      <Box sx={{ minWidth: 0 }}>
        <Typography color="text.secondary" variant="subtitle2" gutterBottom noWrap>
          {title}
        </Typography>
        <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
          {value}
        </Typography>
        {trend && (
          <Typography variant="caption" color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <TrendingUpIcon fontSize="small" />
            {trend}
          </Typography>
        )}
      </Box>
      <Box sx={{ color: 'primary.main', flexShrink: 0 }}>{icon}</Box>
    </Stack>
  </Paper>
)

interface ModelPerformance {
  count: number
  totalProfit: number
}

interface TopModel {
  model: string
  count: number
  avgProfit: number
}

const SuperAdminHome = () => {
  const { vehicles, isLoading } = useInventory('master_inventory')
  const [dateRange, setDateRange] = useState<string>('this_month')
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null)
  const [isTopModelsModalOpen, setIsTopModelsModalOpen] = useState(false)
  const [isPendingSalesModalOpen, setIsPendingSalesModalOpen] = useState(false)
  const { userInfo } = useAuth()
  const isOwner = userInfo?.isOwner
  console.log('isOwner', isOwner)
  console.log('userInfo', userInfo)

  // Get the date range based on selection
  const getDateRange = useCallback(() => {
    const now = new Date()
    
    switch (dateRange) {
      case 'this_month':
        return {
          start: new Date(now.getFullYear(), now.getMonth(), 1),
          end: now
        }
      case 'last_6_months':
        return {
          start: new Date(now.getFullYear(), now.getMonth() - 6, 1),
          end: now
        }
      case 'all_time':
        return {
          start: new Date(2000, 0, 1),
          end: now
        }
      case 'custom':
        return {
          start: customStartDate?.toDate() || now,
          end: customEndDate?.toDate() || now
        }
      default:
        return {
          start: new Date(now.getFullYear(), now.getMonth(), 1),
          end: now
        }
    }
  }, [dateRange, customStartDate, customEndDate])

  // Helper function to safely parse price values
  const parsePrice = (price: string | number | undefined | null): number => {
    try {
      if (price === null || price === undefined) return 0
      
      // If it's already a number, return it
      if (typeof price === 'number') return price

      // If it's a string, clean and parse it
      if (typeof price === 'string') {
        const cleanedPrice = price.replace(/[^0-9.-]+/g, '')
        const parsedPrice = parseFloat(cleanedPrice)
        return isNaN(parsedPrice) ? 0 : parsedPrice
      }

      return 0
    } catch (error) {
      console.error('Error parsing price:', price, error)
      return 0
    }
  }

  // Helper function to safely parse dates
  const parseDate = (dateValue: any): Date | null => {
    try {
      if (!dateValue) return null

      // Handle Firestore Timestamp
      if (dateValue?.seconds) {
        return new Date(dateValue.seconds * 1000)
      }

      // Handle string dates with "at" format (e.g. "January 3, 2025 at 4:38:22 PM GMT+1")
      if (typeof dateValue === 'string' && dateValue.includes(' at ')) {
        // Remove the "at" and try parsing
        const cleanedDate = dateValue.replace(' at ', ' ')
        const date = new Date(cleanedDate)
        if (!isNaN(date.getTime())) {
          return date
        }
      }

      // Handle string dates
      if (typeof dateValue === 'string') {
        const date = new Date(dateValue)
        return isNaN(date.getTime()) ? null : date
      }

      // Handle Date objects
      if (dateValue instanceof Date) {
        return isNaN(dateValue.getTime()) ? null : dateValue
      }

      return null
    } catch (error) {
      console.error('Error parsing date:', dateValue, error)
      return null
    }
  }

  // Helper to get display text for the current date range
  const getDateRangeDisplayText = useCallback(() => {
    switch (dateRange) {
      case 'this_month':
        return 'This Month'
      case 'last_6_months':
        return 'Last 6 Months'
      case 'all_time':
        return 'All Time'
      case 'custom':
        return `${customStartDate?.format('MM/DD/YY')} - ${customEndDate?.format('MM/DD/YY')}`
      default:
        return 'This Month'
    }
  }, [dateRange, customStartDate, customEndDate])

  const calculatedMetrics = useMemo(() => {
    if (!vehicles) return null

    const { start, end } = getDateRange()

    // Only log sold vehicles
    const soldVehicles = vehicles.filter(v => v.status?.toLowerCase() === 'sold')
    
    // Filter sold vehicles within date range (for most metrics)
    const soldInRange = dateRange === 'all_time' 
      ? soldVehicles 
      : soldVehicles.filter(vehicle => {
          const soldDate = parseDate(vehicle.sold_date)
          if (!soldDate) {
            console.log('No valid sold date for vehicle:', vehicle.vin)
            return false
          }
          const isInRange = soldDate >= start && soldDate <= end
          console.log('Date comparison:', {
            vin: vehicle.vin,
            soldDate: soldDate.toISOString(),
            start: start.toISOString(),
            end: end.toISOString(),
            isInRange
          })
          return isInRange
        })

    // Calculate average days to sell for ALL sold vehicles, regardless of date range
    const daysToSell = soldVehicles
      .map(vehicle => {
        const addedDate = parseDate(vehicle.date_added)
        const soldDate = parseDate(vehicle.sold_date)
        
        if (!addedDate || !soldDate) {
          console.log('Missing dates for vehicle:', {
            vin: vehicle.vin,
            added: vehicle.date_added,
            sold: vehicle.sold_date
          })
          return null
        }

        const days = Math.floor((soldDate.getTime() - addedDate.getTime()) / (1000 * 60 * 60 * 24))
        
        console.log('Days to sell calculation:', {
          vin: vehicle.vin,
          added: addedDate.toISOString(),
          sold: soldDate.toISOString(),
          days
        })

        return days
      })
      .filter((days): days is number => days !== null && !isNaN(days) && days >= 0)

    const avgDaysToSell = daysToSell.length 
      ? daysToSell.reduce((sum, days) => sum + days, 0) / daysToSell.length 
      : 0

    console.log('Average days to sell calculation:', {
      totalVehicles: daysToSell.length,
      allDays: daysToSell,
      average: avgDaysToSell
    })

    // Calculate top performing models with initial empty object
    const modelPerformance = soldInRange
      .reduce((acc: Record<string, ModelPerformance>, vehicle) => {
        const model = vehicle.model || 'Unknown'
        if (!acc[model]) {
          acc[model] = { count: 0, totalProfit: 0 }
        }
        const profit = parsePrice(vehicle.profit)
        acc[model].count++
        acc[model].totalProfit += profit
        return acc
      }, {})

    const topModels: TopModel[] = Object.entries(modelPerformance)
      .map(([model, data]) => ({
        model,
        count: data.count,
        avgProfit: data.totalProfit / data.count
      }))
      .sort((a, b) => b.avgProfit - a.avgProfit)
      .slice(0, 5)

    console.log('Top models in range:', topModels)

    const activeVehicles = vehicles.filter(vehicle => 
      vehicle.status === 'Available' || vehicle.status === 'Pending Sale'
    )

    // Calculate inventory value with initial value of 0
    const inventoryValue = activeVehicles.reduce((sum, vehicle) => {
      return sum + parsePrice(vehicle.optic_list_price)
    }, 0)

    return {
      activeInventory: activeVehicles.length,
      soldThisMonth: soldInRange.length,
      revenueThisMonth: soldInRange.reduce((sum, vehicle) => sum + parsePrice(vehicle.optic_list_price), 0),
      averageProfit: soldInRange.length ? 
        soldInRange.reduce((sum, vehicle) => sum + parsePrice(vehicle.profit), 0) / soldInRange.length 
        : 0,
      inventoryValue,
      avgDaysToSell,
      topPerformingModels: topModels,
      dateRange: {
        start: start.toLocaleDateString(),
        end: end.toLocaleDateString()
      },
      modelPerformance
    }
  }, [vehicles, getDateRange, dateRange])

  // Get sold vehicles within date range
  const soldVehicles = useMemo(() => {
    if (!vehicles) return []
    
    const { start, end } = getDateRange()

    // If all_time is selected, just filter for sold status
    if (dateRange === 'all_time') {
      return vehicles.filter(v => v.status?.toLowerCase() === 'sold')
    }

    // Otherwise, filter by both status and date range
    return vehicles.filter(v => {
      if (v.status?.toLowerCase() !== 'sold') return false
      const soldDate = parseDate(v.sold_date)
      if (!soldDate) return false
      return soldDate >= start && soldDate <= end
    })
  }, [vehicles, getDateRange, dateRange])

  if (isLoading) {
    return <CircularProgress />
  }

  if (!calculatedMetrics) {
    return <Typography>No data available</Typography>
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 4 } }}>
      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        spacing={2} 
        sx={{ mb: 4 }} 
        alignItems={{ xs: 'stretch', sm: 'center' }}
      >
        <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
          Business Overview
        </Typography>
        
        <FormControl sx={{ minWidth: { xs: '100%', sm: 200 } }}>
          <InputLabel>Date Range</InputLabel>
          <Select
            value={dateRange}
            label="Date Range"
            onChange={(e) => setDateRange(e.target.value)}
          >
            <MenuItem value="this_month">This Month</MenuItem>
            <MenuItem value="last_6_months">Last 6 Months</MenuItem>
            <MenuItem value="all_time">All Time</MenuItem>
            <MenuItem value="custom">Custom Range</MenuItem>
          </Select>
        </FormControl>

        {dateRange === 'custom' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              <DatePicker
                label="Start Date"
                value={customStartDate}
                onChange={(newValue: Dayjs | null) => setCustomStartDate(newValue)}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              />
              <DatePicker
                label="End Date"
                value={customEndDate}
                onChange={(newValue: Dayjs | null) => setCustomEndDate(newValue)}
                minDate={customStartDate || undefined}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              />
            </Stack>
          </LocalizationProvider>
        )}
      </Stack>

      <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Active Inventory"
            value={calculatedMetrics.activeInventory.toString()}
            icon={<InventoryIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title={`Sold (${getDateRangeDisplayText()})`}
            value={calculatedMetrics.soldThisMonth.toString()}
            icon={<LocalShippingIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        {isOwner && (
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title={`Revenue (${getDateRangeDisplayText()})`}
              value={formatPrice(calculatedMetrics.revenueThisMonth.toString())}
              icon={<MonetizationOnIcon sx={{ fontSize: 40 }} />}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Avg Days to Sell (All Time)"
            value={Math.round(calculatedMetrics.avgDaysToSell).toString()}
            icon={<TimerIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
      </Grid>

      {/* Vehicle Distribution Chart - shown to everyone at the top */}
      <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <VehicleTypeDistribution vehicles={vehicles || []} />
        </Grid>
      </Grid>

      {!isOwner ? (
        // Non-owner view - nothing additional to show
        <></>
      ) : (
        // Owner view - additional charts and metrics
        <>
          <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mb: 4 }}>
            <Grid item xs={12} md={8}>
              <SalesTrendChart 
                vehicles={vehicles || []} 
                dateRange={getDateRange()}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfitDistributionChart 
                vehicles={vehicles || []} 
                dateRange={getDateRange()}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <PendingSalesOverview
                vehicles={vehicles}
                onClick={() => setIsPendingSalesModalOpen(true)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TopPerformancePreview
                vehicles={soldVehicles}
                onClick={() => setIsTopModelsModalOpen(true)}
                dateRangeText={getDateRangeDisplayText()}
              />
            </Grid>
          </Grid>
        </>
      )}

      {calculatedMetrics && (
        <TopModelsModal
          open={isTopModelsModalOpen}
          onClose={() => setIsTopModelsModalOpen(false)}
          vehicles={soldVehicles}
          dateRange={calculatedMetrics.dateRange}
        />
      )}

      <PendingSalesModal
        open={isPendingSalesModalOpen}
        onClose={() => setIsPendingSalesModalOpen(false)}
        vehicles={vehicles}
      />
    </Box>
  )
}

export default SuperAdminHome
