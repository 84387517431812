import { collection, doc, writeBatch } from '@firebase/firestore'
import { db } from 'core/config/firebase'
import { format, toZonedTime } from 'date-fns-tz'
import { loadThumbnailsinFirebase, ThumbnailRequest } from './scraperUtils'

export function generateStockNumber(existingStockNumbers: string[], firstName: string, lastName: string) {
  const prefix = firstName.charAt(0).toUpperCase().concat(lastName.charAt(0).toUpperCase())
  const relevantCodes = existingStockNumbers
    .filter(code => code.startsWith(prefix))
    .map(code => parseInt(code.substring(2, 10)))
  const nextNumber = Math.max(...relevantCodes) + 1
  const nextCode = `${prefix}${nextNumber.toString().padStart(5, '0')}`
  return nextCode
}

export const getTextWidth = (text: string, font: string = '14px Arial'): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }
  return 0
}

export const formatPrice = (value: string | undefined) => {
try{
  if (!value || value.trim() === '' || isNaN(Number(value))) return ''
  const cleanedValue = value.replace(/[$,]/g, '')
  const numValue = Number(cleanedValue)

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(numValue) ? 0 : 2,
    maximumFractionDigits: 2,
    }).format(numValue)
  } catch (error) {
    return 'N/A'
  }
}

export const formatDistance = (value: string | undefined) => {
  try {
    if (!value || value.trim() === '' || isNaN(Number(value))) return ''
    const numValue = Number(value)
    return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    }).format(numValue)
  } catch (error) {
    return 'N/A'
  }
}

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const formatDateTime = (value: string | undefined | { seconds: number; nanoseconds: number }) => {
  try {
    if (!value) return ''
    
    let utcDate: Date
    if (typeof value === 'object' && 'seconds' in value) {
      // Handle Firebase Timestamp
      utcDate = new Date(value.seconds * 1000 + value.nanoseconds / 1000000)
    } else if (typeof value === 'string') {
      // Handle string date
      utcDate = new Date(value)
    } else {
      return ''
    }

    if (isNaN(utcDate.getTime())) {
      return ''
    }

    const easternTime = toZonedTime(utcDate, 'America/New_York')
    return format(easternTime, 'MM/dd/yyyy')
  } catch (error) {
    console.error('Error formatting date:', error)
    return ''
  }
}

export default { generateStockNumber }

function sanitizePrice(price: string) {
  const result = parseFloat(price.replace(/[^0-9.-]+/g, ''))
  return isNaN(result) ? null : result
}

export const calculateProfit = (buyPrice: string, sellPrice: string): string => {
  const buyPriceNum = sanitizePrice(buyPrice)
  const sellPriceNum = sanitizePrice(sellPrice)

  if (!buyPriceNum || !sellPriceNum) {
    return ''
  }
  return String(sellPriceNum - buyPriceNum)
}

export const sanitizeNumber = (value: string | number | boolean) => {
  if (typeof value === 'string') {
    return value.replace(/,/g, '')
  }

  return value
}

export function mapPartialToFullVehicle(partialVehicle: Partial<Vehicle>): Vehicle {
  const currentDate = new Date().toISOString()

  return {
    id: String(partialVehicle.id || ''),
    date_added: String(partialVehicle.date_added || currentDate),
    vin: String(partialVehicle.vin || ''),
    who: String(partialVehicle.who || ''),
    stock_number: String(partialVehicle.stock_number || ''),
    year: String(partialVehicle.year || ''),
    make: String(partialVehicle.make || ''),
    model: String(partialVehicle.model || ''),
    size: String(partialVehicle.size || ''),
    miles: String(partialVehicle.miles || ''),
    fuel: String(partialVehicle.fuel || ''),
    location: String(partialVehicle.location || ''),
    cameras: String(partialVehicle.cameras || ''),
    type: String(partialVehicle.type || ''),
    optic_list_price: String(partialVehicle.optic_list_price || ''),
    seller_asking_price: String(partialVehicle.seller_asking_price || ''),
    profit: String(partialVehicle.profit || ''),
    sold_date: String(partialVehicle.sold_date || ''),
    truck_notes: String(partialVehicle.truck_notes || ''),
    phone_number: String(partialVehicle.phone_number || ''),
    email: String(partialVehicle.email || ''),
    follow_up_notes: String(partialVehicle.follow_up_notes || ''),
    entered_by: String(partialVehicle.entered_by || ''),
    payoff_amount: String(partialVehicle.payoff_amount || ''),
    link: String(partialVehicle.link || ''),
    url: String(partialVehicle.url || ''),
    show_on_website: partialVehicle.show_on_website || false,
    photo_storage_path: String(partialVehicle.photo_storage_path || ''),
    photos: partialVehicle.photos || {
      main: '',
      front: '',
      back: '',
      left: '',
      right: '',
      interior: '',
      engine: '',
      trunk: '',
      other: '',
    },
    status: partialVehicle.status || 'Available',
    uploadedPhotoIds: partialVehicle.uploadedPhotoIds || [],
  }
}

export async function storeImportedVehicles(
  vehicles: Vehicle[],
  collectionName: string,
  onProgress: (percent: number) => void,
  subcollection?: {
    name: string
    parentDocId: string
  },
) {
  const processedVehicles = []
  const BATCH_SIZE = 500

  // Create collection ref based on whether subcollection is specified
  const collectionRef = subcollection
    ? collection(db, collectionName, subcollection.parentDocId, subcollection.name)
    : collection(db, collectionName)

  for (let i = 0; i < vehicles.length; i += BATCH_SIZE) {
    const batch = writeBatch(db)
    const vehicleBatch = vehicles.slice(i, i + BATCH_SIZE)
    const downloadBatch: ThumbnailRequest[] = []

    for (let j = 0; j < vehicleBatch.length; j++) {
      const vehicle = vehicleBatch[j]
      try {
        const vehicleRef = doc(collectionRef)
        const newVehicleId = vehicleRef.id

        const storagePath = subcollection
          ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}/${newVehicleId}/thumbnail.jpeg`
          : `${collectionName}/${newVehicleId}/thumbnail.jpeg`

        const vehicleData = {
          ...vehicle,
          id: newVehicleId,
        }

        batch.set(vehicleRef, vehicleData)
        downloadBatch.push({
          firebasePath: storagePath,
          url: vehicle.link,
        })
        processedVehicles.push(vehicleData)

        const progress = ((i + j + 1) / vehicles.length) * 100
        onProgress(progress)
      } catch (error) {
        console.error(`Error processing vehicle ${vehicle.id}:`, error)
      }
    }

    try {
      await loadThumbnailsinFirebase({
        scrapeThumbnailRequests: downloadBatch,
      })
      await batch.commit()
      console.log(`Batch ${i / BATCH_SIZE + 1} committed successfully`)
    } catch (error) {
      console.error('Error committing batch:', error)
      throw error
    }
  }
  console.log('All vehicles processed successfully')
  return processedVehicles
}
