import { addDoc, collection } from '@firebase/firestore'
import { db } from 'core/config/firebase'

interface SendPhotoUploadLinkProps {
  link: string
  email: string
  phoneNumber: string
}

export const sendPhotoUploadLink = async (props: SendPhotoUploadLinkProps) => {
  const { link, email, phoneNumber } = props
  try {
    console.log('Sending photo upload link...')
    if (phoneNumber !== '') {
      console.log('Sending photo upload link via phone...')
      await addDoc(collection(db, 'messages'), {
        to: phoneNumber,
        body: `Here is your photo upload link: ${link}`,
      })
      console.log('Queued message for delivery via phone!')
    }

    if (email !== '') {
      console.log('Sending photo upload link via email...')
      await addDoc(collection(db, 'mail'), {
        to: email,
        message: {
          subject: 'Optic TruckWorks photo upload',
          html: `Here is your photo upload link: ${link}`,
        },
      })
      console.log('Queued message for delivery via email!')
    }

    return true
  } catch (error) {
    console.error('Error in uploading to Firestore:', error)
    return ''
  }
}
