import { Box, Fab, Grid, SwipeableDrawer, Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { AutoSizer, Grid as VirtualGrid, GridCellProps as VirtualGridCellProps } from 'react-virtualized'
import ImportedVehicleCard from './components/Cards/ImportedVehicleCard'

import { FilterList } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { moveFirebaseStorageFile } from 'core/api/inventory/firebaseStorageOperations'
import {
  generateTransferedOpticStockNumber,
  generateTransferedShieldStockNumber,
} from 'core/api/inventory/generateStockNumber'
import useItemsPerRow from 'hooks/screen/useItemsPerRow'
import { useInventory } from 'hooks/useInventory'
import useInventoryFilter from 'hooks/useInventoryFilter'
import React, { useCallback, useEffect, useState } from 'react'
import { LoadingInventory } from '../Management/InventoryManagement/components/LoadingInventory'
import ImportedVehicleForm from './components/Forms/ImportedVehicleForm'
// TODO: improve performance by storing vehicles in array

interface InventoryImporterProps {
  inventoryCollection: string
  subCollection: {
    name: string
    parentDocId: string
  }
}

const InventoryImporter: React.FC<InventoryImporterProps> = ({ inventoryCollection, subCollection }) => {
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const itemsPerRow = useItemsPerRow()

  const [isCardViewLoading, setIsCardViewLoading] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)
  const SPACING = 16

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)

  const { vehicles, deleteDocument } = useInventory(inventoryCollection, subCollection, {
    dontShowUpdates: true,
  })
  const { vehicles: opticVehicles, addDocument: addOpticInventory, updateDocument: updateOpticInventory } = useInventory('master_inventory', undefined, {
    dontShowUpdates: true,
  })
  const { vehicles: shieldVehicles, addDocument: addShieldInventory, updateDocument: updateShieldInventory } = useInventory('shield_inventory', undefined, {
    dontShowUpdates: true,
  })

  const {
    filteredInventory: filteredVehicles,
    FilterUI: SideBarFilter,
    SearchBarUI: SearchBar,
    activeFilters,
    FilterChip,
    ResetChip,
  } = useInventoryFilter(vehicles || [], false)

  const handleClickVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
  }

  useEffect(() => {
    setIsInitialPageLoad(true)
    const timer = setTimeout(() => {
      setIsInitialPageLoad(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const uType = userInfo?.user_type

  useEffect(() => {
    setIsCardViewLoading(true)
    // Only show loading state briefly if we have data
    const timer = setTimeout(() => {
      if (vehicles) {
        setIsCardViewLoading(false)
        setIsInitialLoad(false)
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [vehicles])

  const handleTransferVehicle = async (
    newVehicleData: Omit<Vehicle, 'id'>,
    destInventoryCollection: 'master_inventory' | 'shield_inventory',
  ) => {
    if (!selectedVehicle) return

    const existingOpticStockNumbers =
      (opticVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []
    const existingShieldStockNumbers =
      (shieldVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []

    const movePhoto = (
      oldDocId: string,
      newDocId: string,
      collectionName: 'master_inventory' | 'shield_inventory',
    ) => {
      moveFirebaseStorageFile(
        `${inventoryCollection}/${subCollection.parentDocId}/${subCollection.name}/${oldDocId}/thumbnail.jpeg`,
        `${collectionName}/${newDocId}/thumbnail.jpeg`,
      )
        .then(newURl => {
          const folderPath = newURl.split('/').slice(0, -1).join('/')
          if (destInventoryCollection === 'master_inventory') {
            updateOpticInventory({ id: newDocId, data: { photo_storage_path: folderPath } })
          } else {
            updateShieldInventory({ id: newDocId, data: { photo_storage_path: folderPath } })
          }
          console.log('moved photo succefully to', folderPath)
        })
        .catch(error => {
          console.log('error moving photo', error)
        })
    }

    let newStockNumber: string

    if (destInventoryCollection === 'master_inventory') {
      if ((opticVehicles as Vehicle[]).map(v => v.vin).includes(selectedVehicle.vin)) return
      newStockNumber = generateTransferedOpticStockNumber(
        existingOpticStockNumbers,
        userInfo?.first_name || '',
        userInfo?.last_name || '',
      )
    } else {
      if ((shieldVehicles as Vehicle[]).map(v => v.vin).includes(selectedVehicle.vin)) return
      newStockNumber = generateTransferedShieldStockNumber(existingShieldStockNumbers)
    }

    const newVehicle = {
      ...newVehicleData,
      status: 'Available',
      stock_number: newStockNumber,
      // photo_storage_path: `${destInventoryCollection}/${vehicleId}`,
      date_added: new Date().toISOString(),
    }
    if (destInventoryCollection === 'master_inventory') {
      addOpticInventory(newVehicle, {
        onSuccess: vehicleId => movePhoto(selectedVehicle.id, vehicleId, 'master_inventory'),
      })
    } else {
      addShieldInventory(newVehicle, {
        onSuccess: vehicleId => movePhoto(selectedVehicle.id, vehicleId, 'shield_inventory'),
      })
    }

    deleteDocument(selectedVehicle.id)
    showSnackbar(
      `Vehicle transferred to ${destInventoryCollection === 'master_inventory' ? 'optic' : 'shield'} inventory`,
      'success',
    )
    setSelectedVehicle(null)
  }

  const cellRenderer = useCallback(
    ({ columnIndex, rowIndex, key, style }: VirtualGridCellProps) => {
      const index = rowIndex * itemsPerRow + columnIndex
      const vehicle = filteredVehicles[index]

      const cellStyle = {
        ...style,
        padding: SPACING,
      }

      if (!vehicle) return null

      return (
        <div key={key} style={cellStyle}>
          <ImportedVehicleCard
            collectionName={inventoryCollection}
            subCollection={subCollection}
            vehicle={vehicle}
            cardHeight={(style.height as number) - SPACING}
            onClick={() => handleClickVehicle(vehicle)}
            onDeleteClick={() => deleteDocument(vehicle.id)}
            isAdmin={uType === 'admin'}
          />
        </div>
      )
    },
    [filteredVehicles, itemsPerRow, inventoryCollection, subCollection, uType],
  )

  if (isInitialPageLoad) {
    return <LoadingInventory />
  }

  const VehicleFilter = () => {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    if (!isMobile) {
      return (
        <Box
          sx={{
            width: '300px',
            flexShrink: 0,
            position: 'sticky',
            top: 24,
            alignSelf: 'flex-start',
          }}
        >
          <SideBarFilter />
        </Box>
      )
    }

    return (
      <Box>
        <Fab
          variant='extended'
          size='medium'
          color='primary'
          aria-label='filter'
          onClick={() => setIsFilterOpen(true)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'fixed',
            bottom: 16,
            left: 16,
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterList sx={{ marginRight: 1 }} />
            <Typography variant='button'>
              Filter {activeFilters.length > 0 && `(${activeFilters.length})`}
            </Typography>
          </Box>
        </Fab>
        <SwipeableDrawer
          open={isFilterOpen}
          onOpen={() => setIsFilterOpen(true)}
          onClose={() => setIsFilterOpen(false)}
          anchor='bottom'
          sx={{
            '& .MuiDrawer-paper': {
              maxHeight: '80vh',
              overflow: 'auto',
            },
          }}
        >
          <SideBarFilter />
        </SwipeableDrawer>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      }}
    >
      <SearchBar />
      <Box
        display='flex'
        flexWrap='wrap'
        gap={1}
        mb={isMobile ? 0 : 2}
      >
        {activeFilters.map(([key, value]) => (
          <FilterChip key={`${key}-${value}`} filterKey={key} value={value} />
        ))}
        {activeFilters.length > 0 && <ResetChip />}
      </Box>
      {!vehicles ? (
        <LoadingInventory />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexGrow: 1,
            overflow: 'auto',
            height: '100%',
            gap: 2,
          }}
        >
          <VehicleFilter />
          <Box sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
            {isCardViewLoading || (isInitialLoad && !vehicles) ? (
              Array.from({ length: 12 }).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <Box sx={{ height: '300px', bgcolor: 'background.paper', p: 2 }}>
                    <Skeleton variant='rectangular' width='100%' height={160} />
                    <Skeleton variant='text' width='60%' />
                    <Skeleton variant='text' width='40%' />
                  </Box>
                </Grid>
              ))
            ) : (
              <AutoSizer>
                {({ height, width }) => {
                  const columnWidth = width / itemsPerRow + SPACING
                  const rowHeight = (isMobile ? 220 : 600) + SPACING
                  return (
                    <VirtualGrid
                      cellRenderer={cellRenderer}
                      columnCount={itemsPerRow}
                      columnWidth={columnWidth}
                      height={height}
                      rowCount={Math.ceil(filteredVehicles.length / itemsPerRow)}
                      rowHeight={rowHeight}
                      width={width + SPACING}
                      overscanRowCount={2}
                      style={{ outline: 'none' }}
                    />
                  )
                }}
              </AutoSizer>
            )}
          </Box>
        </Box>
      )}
      {selectedVehicle && (
        <ImportedVehicleForm
          open={!!selectedVehicle}
          vehicle={selectedVehicle}
          onTransferVehicle={handleTransferVehicle}
          onClose={() => setSelectedVehicle(null)}
        />
      )}
    </Box>
  )
}

export default InventoryImporter
